import React from 'react';
import './styles/grid-component.scss';
// import my from '../public/MY.svg';


const Grid = ({ classes, children, grid2 }) => {
  return (
    <div className={`grid-component ${classes} ${grid2 ? 'grid-2' : ''}`}>
      {children}
    </div>
  );
};

export default Grid;

import React from 'react';
import Grid from './Grid';
import GridBlock from './GridBlock';
import './styles/preview.scss';
import {NavLink} from 'react-router-dom';

function AllWorks() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);
  return (
    <div className="animation-work">
      <Grid grid2>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Infinity" center>
            <div className="preview" style={{ backgroundImage: 'url(/8_ruhanka.gif)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Battery. Character design" center>
            <div className="preview" style={{ backgroundImage: 'url(/battery_cat.jpg)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Walk cycle" center>
            <div className="preview" style={{ backgroundImage: 'url(/walk_light.gif)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="NY in Ukraine" center>
            <div className="preview" style={{ backgroundImage: 'url(/ny.gif)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Team-lead" center>
            <div className="preview" style={{ backgroundImage: 'url(/teamlead.gif)'}} />
          </GridBlock>
        </NavLink>


        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Picnic" center>
            <div className="preview" style={{ backgroundImage: 'url(/frog.jpg)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Stealth" center>
            <div className="preview" style={{ backgroundImage: 'url(/stealth.gif)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Gemini" center>
            <div className="preview" style={{ backgroundImage: 'url(/gemini.jpg)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Gemini" center>
            <div className="preview" style={{ backgroundImage: 'url(/maryna_yanul_map-of-my-anxiety.png)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Stand with Ukraine" center>
            <div className="preview" style={{ backgroundImage: 'url(/peace-ukraine_1.png)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Love story of Right and Left legs" center>
            <div className="preview" style={{ backgroundImage: 'url(/legs.gif)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Gandalf" center>
            <div className="preview" style={{ backgroundImage: 'url(/gandalf.png)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Legolas" center>
            <div className="preview" style={{ backgroundImage: 'url(/Legolas_1_w.jpg)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Virgo" center>
            <div className="preview" style={{ backgroundImage: 'url(/virgo.png)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Self-portrait" center>
            <div className="preview" style={{ backgroundImage: 'url(/sad_light.gif)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Girl" center>
            <div className="preview" style={{ backgroundImage: 'url(/girl.jpg)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/" className="hide-link-style">
          <GridBlock description="Wednesday" center>
            <div className="preview" style={{ backgroundImage: 'url(/wednesday.jpg)'}} />
          </GridBlock>
        </NavLink>

        <NavLink exact to="/watermelon-lover-animation" className="hide-link-style">
          <GridBlock description="Weekly animation. Watermelon lover" center clickable>
            <div className="preview" style={{ backgroundImage: 'url(/final-boy-watermelon.gif)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/projector-motion-design-admission-work" className="hide-link-style">
          <GridBlock description="Scary movie" center clickable>
            <div className="preview" style={{ backgroundImage: 'url(/film.gif)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/usual-sticker-pack" className="hide-link-style">
          <GridBlock description="Usual sticker pack for Telegram" center clickable>
            <div className="preview" style={{ backgroundImage: 'url(/stickers/panic.png)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/cute-girl-sticker-pack" className="hide-link-style">
          <GridBlock description="Cute-girl sticker pack for Telegram" center clickable>
            <div className="preview" style={{ backgroundImage: 'url(/cute-girl/sticker-16.png)'}} />
          </GridBlock>
        </NavLink>
      </Grid>
    </div>
  );
}

export default AllWorks;

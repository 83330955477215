import React from 'react';
import Helmet from 'react-helmet';
// import my from '../public/MY.svg';
import ListOfSkills from './ListOfSkills.js';

function CV() {
  // document.getElementById('content').scrollTop = 0;

  return (
    <div className="cv">
      <Helmet>
        <title>Maryna Yanul CV</title>
      </Helmet>
      {/*<p>Strong UI developer with UX qualification</p>*/}

      {/*<p>Hi visitor, thank you for stopping by.</p>*/}
      {/*<p>Hi visitor, thank you for stopping by.</p>*/}
      <div className="cv-skills-list">
        <ListOfSkills
          title="Frameworks/ technologies"
          skills={[
            'Meteor', 'React', 'Redux', 'WebGL', 'SemanticUI/Bootstrap/Materialize',
          ]}
        />
        <ListOfSkills
          title="Programming languages"
          skills={[
            'JavaScript', 'CSS/SCSS/LESS',
          ]}
        />
        <ListOfSkills
          title="Development tools"
          skills={[
            'Git/Bitbucket', 'Figma', 'Zeplin/Avocode', 'Sketch/Photoshop', 'Procreate',
          ]}
        />
        <ListOfSkills
          title="Databases"
          skills={[
            'MongoDB', 'Firebase',
          ]}
        />
        <ListOfSkills
          title="Methodologies"
          skills={[
            'Agile', 'Kanban', 'SCRUM',
          ]}
        />
        <ListOfSkills
          title="Communication languages"
          skills={[
            'Ukrainian (native)', 'Russian (native)', 'English (advanced low)', 'Korean (beginner)',
          ]}
        />
      </div>

      <h3>Projects/Experience</h3>

      <div className="print-no-wrap">
        <h4 className="project-name">Project 1 (NDA)</h4>
        <div className="experience">
          <div className="experience-block">
            <h4>Customer</h4>
            <p>Swedish company</p>
          </div>
          <div className="experience-block">
            <h4>Involvement duration</h4>
            <p>5 years (now)</p>
          </div>
          <div className="experience-block">
            <h4>Project role</h4>
            <p>Full-stack JS developer</p>
          </div>
          <div className="experience-block">
            <h4>Project team size</h4>
            <p>8 team members</p>
          </div>
          <div className="experience-block">
            <h4>Responsibilities</h4>
            <p>ui development</p>
            <p>decision-making</p>
            <p>negotiating and mediating</p>
            <p>participant in Product Discovery stage</p>
          </div>
          <div className="experience-block">
            <h4>Tools & Technologies:</h4>
            <p>React, Redux, Meteor, Apollo,<br />GraphQL, Scss, SemanticUI, TestCafe</p>
          </div>
        </div>
      </div>

      <div className="print-no-wrap">
        <h4 className="project-name">Project 2 (NDA)</h4>
        <div className="experience">
          <div className="experience-block">
            <h4>Customer</h4>
            <p>Ukrainian company</p>
          </div>
          <div className="experience-block">
            <h4>Involvement duration</h4>
            <p>9 months</p>
          </div>
          <div className="experience-block">
            <h4>Project role</h4>
            <p>Full-stack JS developer</p>
          </div>
          <div className="experience-block">
            <h4>Project team size</h4>
            <p>6 team members</p>
          </div>
          <div className="experience-block">
            <h4>Responsibilities</h4>
            <p>ui development</p>
            <p>negotiating and mediating</p>
            <p>team lead in supporting stage</p>
          </div>
          <div className="experience-block">
            <h4>Tools & Technologies:</h4>
            <p>React, Redux, Apollo, GraphQL, Node.js, Scss</p>
          </div>
        </div>
      </div>

      <div className="print-no-wrap">
        <h4 className="project-name">Project 3 (NDA)</h4>
        <div className="experience">
          <div className="experience-block">
            <h4>Customer</h4>
            <p>Ukrainian company</p>
          </div>
          <div className="experience-block">
            <h4>Involvement duration</h4>
            <p>3 months</p>
          </div>
          <div className="experience-block">
            <h4>Project role</h4>
            <p>Front-end developer</p>
          </div>
          <div className="experience-block">
            <h4>Project team size</h4>
            <p>5 team members</p>
          </div>
          <div className="experience-block">
            <h4>Responsibilities</h4>
            <p>UI/UX design</p>
            <p>ui development</p>
          </div>
          <div className="experience-block">
            <h4>Tools & Technologies:</h4>
            <p>AngularJS, Jinja2, Bootstrap, Less</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CV;

import React from 'react';
// import my from '../public/MY.svg';


function Blog() {
  return (
    <div className="blog">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus sit amet volutpat consequat. Sit amet cursus sit amet dictum sit amet justo. Egestas sed tempus urna et pharetra. Imperdiet sed euismod nisi porta lorem mollis. Consectetur a erat nam at lectus urna duis convallis. Purus in mollis nunc sed id semper. Erat velit scelerisque in dictum non consectetur a erat. Nisl vel pretium lectus quam id. Ac ut consequat semper viverra nam libero justo laoreet sit. Tincidunt lobortis feugiat vivamus at augue eget. Volutpat ac tincidunt vitae semper quis lectus nulla. Nec nam aliquam sem et tortor consequat id porta nibh. Eget dolor morbi non arcu. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Amet dictum sit amet justo donec enim diam vulputate.

      In hac habitasse platea dictumst quisque sagittis purus sit. Vitae nunc sed velit dignissim sodales. Morbi tristique senectus et netus et malesuada fames ac. Et magnis dis parturient montes. Ullamcorper a lacus vestibulum sed. Mattis aliquam faucibus purus in massa. Eu mi bibendum neque egestas congue. Nibh sed pulvinar proin gravida hendrerit lectus a. Consequat id porta nibh venenatis cras sed. Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque in. Bibendum at varius vel pharetra vel turpis nunc eget. Sed augue lacus viverra vitae. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Mattis molestie a iaculis at erat pellentesque adipiscing. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Vestibulum lectus mauris ultrices eros. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Mattis enim ut tellus elementum.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus sit amet volutpat consequat. Sit amet cursus sit amet dictum sit amet justo. Egestas sed tempus urna et pharetra. Imperdiet sed euismod nisi porta lorem mollis. Consectetur a erat nam at lectus urna duis convallis. Purus in mollis nunc sed id semper. Erat velit scelerisque in dictum non consectetur a erat. Nisl vel pretium lectus quam id. Ac ut consequat semper viverra nam libero justo laoreet sit. Tincidunt lobortis feugiat vivamus at augue eget. Volutpat ac tincidunt vitae semper quis lectus nulla. Nec nam aliquam sem et tortor consequat id porta nibh. Eget dolor morbi non arcu. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Amet dictum sit amet justo donec enim diam vulputate.

      In hac habitasse platea dictumst quisque sagittis purus sit. Vitae nunc sed velit dignissim sodales. Morbi tristique senectus et netus et malesuada fames ac. Et magnis dis parturient montes. Ullamcorper a lacus vestibulum sed. Mattis aliquam faucibus purus in massa. Eu mi bibendum neque egestas congue. Nibh sed pulvinar proin gravida hendrerit lectus a. Consequat id porta nibh venenatis cras sed. Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque in. Bibendum at varius vel pharetra vel turpis nunc eget. Sed augue lacus viverra vitae. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Mattis molestie a iaculis at erat pellentesque adipiscing. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Vestibulum lectus mauris ultrices eros. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Mattis enim ut tellus elementum.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus sit amet volutpat consequat. Sit amet cursus sit amet dictum sit amet justo. Egestas sed tempus urna et pharetra. Imperdiet sed euismod nisi porta lorem mollis. Consectetur a erat nam at lectus urna duis convallis. Purus in mollis nunc sed id semper. Erat velit scelerisque in dictum non consectetur a erat. Nisl vel pretium lectus quam id. Ac ut consequat semper viverra nam libero justo laoreet sit. Tincidunt lobortis feugiat vivamus at augue eget. Volutpat ac tincidunt vitae semper quis lectus nulla. Nec nam aliquam sem et tortor consequat id porta nibh. Eget dolor morbi non arcu. Elit pellentesque habitant morbi tristique senectus et netus et malesuada. Amet dictum sit amet justo donec enim diam vulputate.

      In hac habitasse platea dictumst quisque sagittis purus sit. Vitae nunc sed velit dignissim sodales. Morbi tristique senectus et netus et malesuada fames ac. Et magnis dis parturient montes. Ullamcorper a lacus vestibulum sed. Mattis aliquam faucibus purus in massa. Eu mi bibendum neque egestas congue. Nibh sed pulvinar proin gravida hendrerit lectus a. Consequat id porta nibh venenatis cras sed. Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque in. Bibendum at varius vel pharetra vel turpis nunc eget. Sed augue lacus viverra vitae. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Mattis molestie a iaculis at erat pellentesque adipiscing. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Vestibulum lectus mauris ultrices eros. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Mattis enim ut tellus elementum.
    </div>
  );
}

export default Blog;

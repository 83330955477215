/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import '../styles/art-work.scss';

const stickerPack = [
  'sad',
  'hands',
  'camera',
  'friend-zone',
  'flowers',
  'wink',
  'love',
  'adidas',
  'sandwich',
  'cat',
  'strong-laugh',
  'muesli',
  'milk',
  'sleep',
  'sneaker',
  'panic',
  'tampon',
  'fuck',
  'normal',
  'coffee',
  'in-love',
  'instagram',
  'contact',
  'youtube',
  'feet',
  'nervous',
  'slippers',
  'pimply',
  'pizza',
  'leg',
];

function UsualStickerPack() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);

  return (
    <div className="art-work stickers">
      <div className="description-section">
        <div className="name-img" />
        <div className="flexible-block">
          <h3>Sticker pack for Telegram</h3>
          <p>
            The idea was to create simple stickers that are convenient to use every day.<br />
            Try it on Telegram -> <a href="https://tlgrm.ru/stickers/usualpack" className="text-lint" target="_blank">tlgrm.ru/stickers/usualpack</a>
          </p>
        </div>
      </div>
      <div className="examples">{stickerPack.map(name => (
        <div key={name} className="sticker hovered" style={{ backgroundImage: `url(/stickers/${name}.png)` }}>
          <div className={`emoji ${name}`} />
        </div>
      ))}</div>
    </div>
  );
}

export default UsualStickerPack;

import React from 'react';
import ContactInfo from './ContactInfo';
import './styles/about-page.scss';

const date = new Date().getFullYear();
const yearsOfExperience = date - 2016;

function About() {
  return (
    <div className="about-page">
      <p>
        Hey there! I'm Maryna Yanul, an illustrator and visual artist from Lviv, Ukraine.
        <br/>
        <br/>
        While my palette spans digital art, illustration, motion design, and animation, I wear another hat as a software engineer with over 7 years of experience.
        <br/>
        Currently, I’m leading the front-end and design aspect for a Swedish project.
        <br/>
        <br/>
        In 2020, I dived into the world of 3D animation, and now, I’m crafting my very first 3D animated short film.
        <br/>
        <br/>
        I can converse well in English, and I'm native in Ukrainian and Russian. Recently, I've embarked on a linguistic journey, dipping my toes into Korean and Spanish.
        <br/>
        <br/>
        And oh, I'm head over heels for film photography. I've always got my camera at the ready! Check out some of my shots at <a href="https://unsplash.com/@marynayanul" target="_blank">Unsplash</a>.
      </p>

      <p>
        Join my instagram acount <a href="https://www.instagram.com/maryna.yanul/" _blank >Maryna Yanul</a>
      </p>

      <br/>
      <br/>
    </div>
  );
}

export default About;

import React from 'react';
import './styles/contacts.scss';

const IntroText = () => {
  return (
    <div>
      <p>Hi! I'm a 3D illustrator with deep passion for character and motion design.</p>
      <p>
        Open for full-time and part-time job.
      </p>
    </div>
  );
};

export default IntroText;

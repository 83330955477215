import React from 'react';

function ListOfSkills({ title, skills }) {
  return (
    <div className="skills-list">
      <h4 className="skills-title">{title}</h4>
      {skills.map((name) => (
        <p key={name} className="skill">{name}</p>
      ))}
    </div>
  )
}
export default ListOfSkills;

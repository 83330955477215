/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import '../styles/art-work.scss';

const stickerPack = [
  'sticker-1',
  'sticker-2',
  'sticker-3',
  'sticker-4',
  'sticker-5',
  'sticker-6',
  'sticker-7',
  'sticker-8',
  'sticker-9',
  'sticker-10',
  'sticker-11',
  'sticker-12',
  'sticker-13',
  'sticker-14',
  'sticker-15',
  'sticker-16',
  'sticker-17',
  'sticker-18',
  'sticker-19',
  'sticker-20',
];

function CuteGirlStickerPack() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);

  return (
    <div className="art-work stickers cute-girl">
      <div className="description-section">
        <div className="name-img" />
        <div className="flexible-block">
          <h3>Sticker pack for Telegram</h3>
          <p>
            The idea was to create simple sticker pack for girls.<br />
            Try it on Telegram -> <a href="https://tlgrm.ru/stickers/cute_cute_girl" className="text-lint" target="_blank">tlgrm.ru/stickers/cute_cute_girl</a>
          </p>
        </div>
      </div>
      <div className="examples">{stickerPack.map(name => (
        <div key={name} className="sticker hovered" style={{ backgroundImage: `url(/cute-girl/${name}.png)` }}>
          <div className={`emoji ${name}`} />
        </div>
      ))}</div>
    </div>
  );
}

export default CuteGirlStickerPack;

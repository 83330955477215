/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import '../styles/test-work.scss';

function WatermelonLoverAnimation() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);

  return (
    <div className="test-work">
      <div className="main-work">
        <div className="img-container">
          <img src="final-boy-watermelon.gif" alt="gif animation"/>
          <p className="img-description">"Watermelon lover"</p>
        </div>
        <div className="description-section">
          <p>This is a weekly animation about a guy who really likes watermelon
            <br/>
            <br/>
            <span>20.07.2020</span>
          </p>

        </div>
      </div>


      <h1>Work Process</h1>

      <img src="sketch-file.png" alt="sketch" />
      <p className="img-description">Storyboard and character design</p>
      <br/>
      <br/>
      <h3>Made illustrations in Illustrator</h3>
      <img src="Screenshot-watermelon-ill.png" alt="Screenshot-illustrator"/>
      <br/>
      <br/>
      <br/>
      <h3>Animated all scenes in After Effect</h3>
      <img src="Screenshot-watermelon-ae.png" alt="Screenshot-ae"/>
      <br/>
      <br/>
      <br/>
      <p>Little spoon of magic and that's all.</p>

      <div className="thanks-container">
        <img src="stickers/hands.png" alt="love"/>
        <p>Thank you for watching ;)</p>
      </div>
    </div>

  );
}

export default WatermelonLoverAnimation;

import React from 'react';


function Page404() {
  return (
    <div className="not-found-page">
      <h1>404</h1>
      <p>Page not found</p>
      but you can go to <a href="/">home page</a>
    </div>
  );
}

export default Page404;

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './styles/test-work.scss';

function AnimationTestWork() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);

  return (
    <div className="test-work">
      <div className="main-work">
        <div className="img-container">
          <img src="film.gif" alt="gif animation"/>
          <p className="img-description">"How do you watch scary movies?"</p>
        </div>
        <div className="description-section">
          <p>This is a test work for admission to the
            <a className="text-lint" href="https://prjctr.online/motion-design-beginning.html" target="_blank">
              <br/>Motion design beginning course
            </a> in Projector online-school.
            <br/>
            <br/>
            <span>16.07.2020</span>
          </p>

        </div>
      </div>


      <h1>Work Process</h1>

      <div className="container">
        <div className="item">
          <img src="sketch.png" alt="sketch"/>
          <p className="img-description">First Sketch</p>
        </div>
        <div className="item">
          <img src="sketch-color.png" alt="sketch-color"/>
          <p className="img-description">Added color mess</p>
        </div>
      </div>

      <h3>Color matching</h3>
      <div className="container">
        <div className="item">
          <img src="color-1.jpeg" alt="color-1"/>
        </div>
        <div className="item">
          <img src="color-2.jpeg" alt="color-2"/>
        </div>
        <div className="item">
          <img src="color-3.jpeg" alt="color-3"/>
        </div>
        <div className="item">
          <img src="color-4.jpeg" alt="color-4"/>
        </div>
      </div>

      <br/>

      <p>It was so hard to choose right color scheme that I created a poll on Instagram</p>
      <br/>

      <div className="container">
        <div className="item">
          <img src="poll-1.JPG" alt="poll-1"/>
          <p className="img-description">Poll on Instagram</p>
        </div>
        <div className="item">
          <img src="poll-2.JPG" alt="poll-2"/>
          <p className="img-description">Results</p>
        </div>
      </div>

      <p>As you can see, it didn't help</p>
      <p>Second looks better for me.</p>

      <h3>Made it in Illustrator</h3>
      <img src="Screenshot-ill.png" alt="Screenshot-illustrator"/>

      <h3>Last step, After Effect</h3>
      <img src="Screenshot-ae.png" alt="Screenshot-ae"/>

      <br/>
      <br/>
      <p>Little spoon of magic and that's all.</p>

      <div className="thanks-container">
        <img src="stickers/hands.png" alt="love"/>
        <p>Thank you for watching ;)</p>
      </div>
    </div>

  );
}

export default AnimationTestWork;

import React from 'react';
import Grid from '../Grid';
import GridBlock from '../GridBlock';
import '../styles/preview.scss';
import {NavLink} from 'react-router-dom';


function StickerPacks() {
  setTimeout(() => document.getElementById('content').scrollTop = 0, 0);
  return (
    <div className="animation-work">
      <Grid grid2>
        <NavLink exact to="/usual-sticker-pack">
          <GridBlock description="Usual sticker pack for Telegram" center clickable clickToSeeMore>
            <div className="preview" style={{ backgroundImage: 'url(/stickers/panic.png)'}} />
          </GridBlock>
        </NavLink>
        <NavLink exact to="/cute-girl-sticker-pack">
          <GridBlock description="Usual sticker pack for Telegram" center clickable clickToSeeMore>
            <div className="preview" style={{ backgroundImage: 'url(/cute-girl/sticker-16.png)'}} />
          </GridBlock>
        </NavLink>
      </Grid>
    </div>
  );
}

export default StickerPacks;

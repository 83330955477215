import React from 'react';
// import my from '../public/MY.svg';

const GridBlock = ({ classes, children, center, height, description, clickable, clickToSeeMore}) => {
  return (
    <div
      className={`grid-block ${classes} ${center ? 'center' : ''} ${clickable ? 'clickable' : ''}`}
      style={{ height }}
    >
      {children}
      {description ? (
        <p className="description">{description}</p>
      ): null}
      {clickToSeeMore ? (
        <p className="see-more">Click to see more</p>
      ) : null}
    </div>
  );
};

export default GridBlock;
